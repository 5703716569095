import React from 'react';

const pointing = {
  '0': 'right',
  '90': 'down',
  '180': 'left',
  '270': 'up',
};

const SVG = ({width = '16px', height = '16px', rotate = 0}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 198.4 198.4"
    className="bi bi-arrow"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{`Arrow ${pointing[rotate]}`}</title>
    <g transform={`rotate(${rotate}, 99.2, 99.2)`}>
      <path
        fillRule="evenodd"
        d="M69.7,168.9c-1,1-2.2,1.5-3.6,1.5c-1.4,0-2.6-0.5-3.6-1.5c-2-2-2-5.2,0-7.1l62.6-62.6L62.6,36.6c-2-2-2-5.2,0-7.1s5.2-2,7.1,0l66.2,66.2c2,2,2,5.2,0,7.1L69.7,168.9z"
      />
    </g>
  </svg>
);

export default SVG;
