import React from 'react';

const SVG = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 198.4 198.4"
    className="bi bi-plus"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Plus</title>
    <g>
      <path
        fillRule="evenodd"
        d="M159.6,88.2h-46.6c-1.6,0-2.7-1.1-2.7-2.7V38.9c0-3.3-2.2-5.5-5.5-5.5h-11c-3.3,0-5.5,2.2-5.5,5.5v46.6c0,1.6-1.1,2.7-2.7,2.7H38.9c-3.3,0-5.5,2.2-5.5,5.5v11c0,3.3,2.2,5.5,5.5,5.5h46.6c1.6,0,2.7,1.1,2.7,2.7v46.6c0,3.3,2.2,5.5,5.5,5.5h11c3.3,0,5.5-2.2,5.5-5.5v-46.6c0-1.6,1.1-2.7,2.7-2.7h46.6c3.3,0,5.5-2.2,5.5-5.5v-11C165,90.4,162.8,88.2,159.6,88.2z"
      />
    </g>
  </svg>
);

export default SVG;
