/* eslint-disable camelcase */
import axios from 'axios';
import {
  GALLERY_FILTER_USED_BY_USER,
  GALLERY_PAGINATION_USED_BY_USER,
  GALLERY_SORT_USED_BY_USER,
  GALLERY_URL_CHANGED,
  IMPRESSIONS_FAILURE,
  IMPRESSIONS_REQUEST,
  IMPRESSIONS_SUCCESS,
  PAGE_VIEW_REQUEST,
  PAGE_VIEW_SUCCESS,
  PAGE_VIEW_FAILURE,
  VISIT_REQUEST,
  VISIT_SUCCESS,
  VISIT_FAILURE,
  PRODUCT_CLICK_REQUEST,
  PRODUCT_CLICK_SUCCESS,
  PRODUCT_CLICK_FAILURE,
} from '../actionTypes';
import constantsFactory from '../../src/utils/constants';

const {API} = constantsFactory();

const buildFormData = (formData, data, parentKey: string | undefined = undefined) => {
  if (data && typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
};

const jsonToFormData = (data) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const filterUsedByUser = () => ({
  type: GALLERY_FILTER_USED_BY_USER,
});

export const paginationUsedByUser = () => ({
  type: GALLERY_PAGINATION_USED_BY_USER,
});

export const sortUsedByUser = () => ({
  type: GALLERY_SORT_USED_BY_USER,
});

export const urlChanged = () => ({
  type: GALLERY_URL_CHANGED,
});

// Needs to be triggered on page landing and url change
export const pageView = (url) => {
  return {
    types: [PAGE_VIEW_REQUEST, PAGE_VIEW_SUCCESS, PAGE_VIEW_FAILURE],
    callAPI: () =>
      axios.post(`${API.PAGE_VIEW}`, JSON.stringify([{event_type: 'pageview', url: url}]), {
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'x-api-key': process.env['API_AUTH_X_API_KEY'],
        },
        withCredentials: true,
      }),
  };
};

// Needs to be triggered after new products are loaded to page
export const impressions = (url: string, products: ImpressionProductsProps[]) => ({
  types: [IMPRESSIONS_REQUEST, IMPRESSIONS_SUCCESS, IMPRESSIONS_FAILURE],
  callAPI: () =>
    axios.post(
      `${API.IMPRESSIONS}`,
      {url, products},
      {
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'x-api-key': process.env['API_AUTH_X_API_KEY'],
        },
        withCredentials: true,
      },
    ),
});

// Triggered only on PDP and Artist page
// On PDP behaves like page view event
// On Artist page gets triggered only on landing
// If we're on PDP send product id, otherwise artist id
export const visit = (page: 'artist' | 'product', id) => {
  const payload = {
    object_type: page === 'artist' ? 'artists.Artist' : 'products.Product',
    object_id: id,
  };
  return {
    types: [VISIT_REQUEST, VISIT_SUCCESS, VISIT_FAILURE],
    callAPI: () =>
      axios.post(`${API.VISIT}`, jsonToFormData(payload), {
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'x-api-key': process.env['API_AUTH_X_API_KEY'],
        },
        withCredentials: true,
      }),
  };
};

export const productClick = (url: string, slug: string) => ({
  types: [PRODUCT_CLICK_REQUEST, PRODUCT_CLICK_SUCCESS, PRODUCT_CLICK_FAILURE],
  callAPI: () =>
    axios.post(
      `${API.PRODUCT_CLICK}`,
      {action: 'product-page', product_slug: slug, url},
      {
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'x-api-key': process.env['API_AUTH_X_API_KEY'],
        },
        withCredentials: true,
      },
    ),
});

interface ImpressionProductsProps {
  above_fold: boolean; // first set of images should be true
  product_slug: string;
  y_pos: number;
}
