import React from 'react';

const StarHalf = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="26 32 148 148"
    className="bi bi-star-half"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Star half</title>
    <g>
      <path
        fillRule="evenodd"
        d="M170.4,82.9c-0.5-1.6-2.1-2.6-4.6-3l-43-6.3l-19.3-39c-1.2-2.3-2.6-3.5-4.2-3.5c-1.7,0-3.1,1.2-4.2,3.5l-19.3,39l-43,6.3c-2.6,0.4-4.1,1.4-4.6,3c-0.5,1.6,0.1,3.3,2,5.1l31.2,30.3l-7.4,42.9c-0.3,1.9-0.1,3.4,0.5,4.4c0.6,1.1,1.6,1.6,2.9,1.6c1,0,2.1-0.3,3.4-1L99.2,146l38.5,20.2c1.3,0.7,2.5,1,3.4,1c1.3,0,2.3-0.5,2.9-1.6c0.6-1.1,0.8-2.5,0.5-4.4l-7.4-42.9L168.3,88C170.2,86.2,170.9,84.5,170.4,82.9z M129.6,110.5l-4.2,4l1,5.7l5.1,30.4l-27.3-14.4l-5.1-2.7V51l13.6,27.6l2.6,5.1l5.7,0.9l30.5,4.5L129.6,110.5z"
      />
    </g>
  </svg>
);

export default StarHalf;
