import React from 'react';

const StarFull = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="26 32 148 148"
    className="bi bi-star-full"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Star full</title>
    <g>
      <path
        fillRule="evenodd"
        d="M170.4,83.9c0-2.1-1.6-3.4-4.8-3.9l-42.9-6.2l-19.2-38.9c-1.1-2.3-2.5-3.5-4.2-3.5c-1.7,0-3.1,1.2-4.2,3.5L75.8,73.8L32.8,80c-3.2,0.5-4.8,1.8-4.8,3.9c0,1.2,0.7,2.6,2.1,4.1l31.1,30.3L54,161.1c-0.1,0.8-0.2,1.4-0.2,1.7c0,1.2,0.3,2.2,0.9,3c0.6,0.8,1.5,1.2,2.7,1.2c1,0,2.2-0.3,3.4-1l38.4-20.2l38.4,20.2c1.2,0.7,2.3,1,3.4,1c1.1,0,2-0.4,2.6-1.2c0.6-0.8,0.9-1.8,0.9-3c0-0.7,0-1.3-0.1-1.7l-7.4-42.8l31-30.3C169.6,86.6,170.4,85.2,170.4,83.9z"
      />
    </g>
  </svg>
);

export default StarFull;
