import React from 'react';

import StarFull from '../icons-af/star-full';
import StarHalf from '../icons-af/star-half';
import StarHollow from '../icons-af/star-hollow';
import './rating.scss';

const star = (index, value, size) => {
  if (index <= Math.round(value)) {
    return <StarFull height={size} width={size} />;
  }

  if (Math.ceil(value) === index) {
    return <StarHalf height={size} width={size} />;
  }

  return <StarHollow height={size} width={size} />;
};

const Rating = ({value, size}: propTypes) => {
  return (
    <span
      className="rating"
      data-stars={value}
      // aria-label={`The rating is ${value} out of 5`}
    >
      {star(1, value, size)}
      {star(2, value, size)}
      {star(3, value, size)}
      {star(4, value, size)}
      {star(5, value, size)}
    </span>
  );
};

interface propTypes {
  value: number;
  size: string;
  readOnly?: boolean;
}

export default Rating;

export {star};
