import React from 'react';

const Location = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="19 28 160 160"
    className="bi bi-location"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Location</title>
    <g>
      <path
        fillRule="evenodd"
        d="M99.2,28.8c-28.6,0-51.9,23.3-51.9,51.9c0,7.5,1.5,14.6,4.5,21.2c13,28.4,37.8,58.3,45.2,66.9c0.5,0.6,1.3,1,2.2,1s1.6-0.4,2.2-1c7.3-8.5,32.2-38.5,45.2-66.9c3-6.6,4.5-13.7,4.5-21.2C151.1,52,127.8,28.8,99.2,28.8zM99.2,107.6c-14.9,0-26.9-12.1-26.9-26.9c0-14.9,12.1-26.9,26.9-26.9s26.9,12.1,26.9,26.9C126.2,95.5,114.1,107.6,99.2,107.6z"
      />
    </g>
  </svg>
);

export default Location;
