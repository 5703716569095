import _ from '../utils/_';

const displayFormat = [
  [2, ',', '.'],
  [2, '.', ','],
  [2, ' ', '.'],
];

const xs = [
  ['AUD', 'A$', '036', 'Australian Dollar', 2],
  ['CAD', 'C$', '124', 'Canadian Dollar'],
  ['EUR', '€', '978', 'Euro'],
  ['GBP', '£', '826', 'Pound Sterling'],
  ['USD', '$', '840', 'US Dollar'],
];

const defaultTo0 = _.defaultTo(0);

const findX = (currency) => xs.find((x) => x[0] === currency) || [];

const symbolFromCurrency = (currency) => findX(currency)[1];

const nameFromCurrency = (currency) => findX(currency)[3];

const addParentheses = (str) => '(' + str + ')';

const formatCurrency = _.curry2((currency, amount) => {
  if (_.isNil(amount)) {
    return '-';
  }

  const x = findX(currency);
  const f = displayFormat[defaultTo0(x[4])];

  const str = x[1] + _.formatNumber(Math.abs(amount), f[0], f[1], f[2]);

  if (amount < 0) {
    return addParentheses(str);
  }

  return str;
});

export {symbolFromCurrency, nameFromCurrency};
export {formatCurrency};
