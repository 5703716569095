import _ from '../utils/_';
import {get, urlencoded} from '../services/artfinder-service';
import {emit} from '../utils/events';
import {pushGAAddToCartEvent} from '../utils/gtm';

const basketServiceFactory = (document, window, data) => {
  const _items =
    _.def(data) && data.basketed_products && data.basketed_products.length > 0
      ? data.basketed_products.filter(Boolean)
      : [];
  const _meta = _.def(data) ? _.dissoc('basketed_products', data) : {};

  const isUpdated = () => _.def(data);

  const items = () => _items || [];

  const meta = () => _meta || {};

  const contains = (item, id = _.prop('id')) =>
    items().some((x) => (x && item && id(x) && id(item) ? id(x) === id(item) : false));

  const fetchAndUpdate = () =>
    get('/api/basket/details/')
      .then((x) => {
        emit('BASKET_UPDATED', basketServiceFactory(document, window, x));
      })
      .catch((err) => {
        console.log('basket updated error', err);
      });

  const add = (item, currency = null) =>
    urlencoded('/basket/a/', {product: item.id})
      .then(fetchAndUpdate)
      .then((resp) => pushGAAddToCartEvent([item], currency))
      .catch((err) => {
        console.log('basket add error', err);
      });

  const addAndRedirect = (item, currency = null) =>
    urlencoded('/basket/a/', {product: item.id})
      .then(fetchAndUpdate)
      .then((resp) => {
        pushGAAddToCartEvent([item], currency);
        window.location.assign('/basket/');
      })
      .catch((err) => {
        console.log('basket add & redirect error', err);
      });

  const addBundle = (product, bundleProduct, currency = null) =>
    urlencoded('/basket/bundle-add/', {
      product_1: product.slug,
      product_2: bundleProduct.slug,
      Bundle: 'Add both to basket',
    })
      .then((resp) => {
        pushGAAddToCartEvent([product, bundleProduct], currency);
        window.location.assign('/basket/');
      })
      .catch((err) => {
        console.log('bundle add error', err);
      });

  if (!isUpdated()) {
    fetchAndUpdate();
  }

  return _.merge(
    {
      items: items,
      add: add,
      addAndRedirect: addAndRedirect,
      addBundle: addBundle,
      contains: contains,
    },
    meta(),
  );
};

const basketFactoryThrottled = _.throttle({}, 5000, basketServiceFactory);

const noopPromise = () => ({
  then: (_) => noopPromise(),
  catch: (_) => noopPromise(),
  finally: (_) => noopPromise(),
});

const basketMock = () => ({
  items: () => [],
  add: (product, currency) => noopPromise(),
  addBundle: (product, bundleProduct, currency) => noopPromise(),
  contains: (_) => false,
});

export default basketFactoryThrottled;

export {basketMock};
