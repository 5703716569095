const modalFactory = ($, _, document, window, KEYS) => (
    openTriggers,
    closeTriggers,
    target,
    options,
) => {
  const ClassName = {
    BACKDROP: 'modal-backdrop',
    BODY_SCROLL_LOCK: 'modal-open',
    FADE: 'fade',
    SHOW: 'show',
  };

  const _default = {
    backdropBg: '#000000',
    backdrop: true,
    keyboard: true,
    show: false,
    backdropTransition: 150,
    backdropTransitionOut: 150,
    modalTransition: 300,
    modalTransitionOut: 300,
    onClose: () => {},
  };

  const opts = _.merge(options, _default);
  let backdropEl = null;
  let bodyActualPadding = '';

  const isOpen = () => $.getAttribute('aria-hidden', target) === 'false';

  const adjustBodyPadding = () => {
    bodyActualPadding = document.body.style.paddingRight;
    const styles = window.getComputedStyle(document.body);
    const computedPadding = styles.getPropertyValue('padding-right');

    document.body.style.paddingRight = `${parseFloat(computedPadding) +
      $.getScrollbarWidth()}px`;
  };

  const resetBodyPadding = () => {
    document.body.style.paddingRight = bodyActualPadding;
  };

  const open = () => {
    $.addClass(ClassName.BODY_SCROLL_LOCK, document.body);
    adjustBodyPadding();

    // start displaying backdrop
    document.body.appendChild(backdropEl);
    $.reflow(backdropEl);
    $.addClass(ClassName.SHOW, backdropEl);

    // start displaying modal (after backdrop's transition ended)
    setTimeout(() => {
      target.style.display = 'block';
      $.reflow(target);
      $.addClass(ClassName.SHOW, target);
    }, opts.backdropTransition);

    // all are shown (after all transitions ended)
    setTimeout(() => {
      $.setAttribute('aria-hidden', 'false', target);
    }, opts.backdropTransition + opts.modalTransition);
  };

  const close = () => {
    $.removeClass(ClassName.SHOW, target);

    setTimeout(() => {
      target.style.display = 'none';
      $.removeClass(ClassName.SHOW, backdropEl);
    }, opts.modalTransitionOut);

    setTimeout(() => {
      $.removeClass(ClassName.BODY_SCROLL_LOCK, document.body);
      resetBodyPadding();
      backdropEl.remove();
      $.setAttribute('aria-hidden', 'true', target);
      opts.onClose(window);
    }, opts.backdropTransitionOut + opts.modalTransitionOut);
  };

  const toggle = () => {
    if (isOpen()) {
      close();
    } else {
      open();
    }
  };

  const openIfClosed = () => {
    if (!isOpen()) {
      open();
    }
  };

  const closeIfOpen = () => {
    if (isOpen()) {
      close();
    }
  };

  const onKeydown = (e) => {
    if (!isOpen()) {
      return;
    }

    if (e.which === KEYS.ESC) {
      close();
      e.preventDefault();
    }
  };

  const handleClickTarget = (e) => {
    if (e.target === target) {
      close();
    }
  };

  const bindEvents = (fn) => {
    // open
    openTriggers.forEach((x) => {
      fn('click', toggle, x);
    });

    // close
    closeTriggers.forEach((x) => {
      fn('click', close, x);
    });
    if (opts.backdrop !== 'static') {
      fn('click', handleClickTarget, target);
    }

    // keyboard
    if (opts.keyboard) {
      fn('keydown', onKeydown, document);
    }
  };

  const destroy = () => {
    closeIfOpen();
    bindEvents($.off);
  };

  const init = () => {
    // build out the backdrop
    backdropEl = document.createElement('div');
    backdropEl.className = ClassName.BACKDROP;
    backdropEl.style.backgroundColor = opts.backdropBg;
    if ($.hasClass(ClassName.FADE, target)) {
      $.addClass(ClassName.FADE, backdropEl);
    }

    bindEvents($.on);
    if (opts.show) {
      open();
    }
  };

  init();

  return {
    isOpen: isOpen,
    open: openIfClosed,
    close: closeIfOpen,
    destroy: destroy,
  };
};

const transitionZero = () => ({
  backdropTransition: 0,
  backdropTransitionOut: 0,
  modalTransition: 0,
  modalTransitionOut: 0,
});

const modalMock = () => ({
  isOpen: () => {},
  open: () => {},
  close: () => {},
  destroy: () => {},
});

export default modalFactory;

export {transitionZero, modalMock};
