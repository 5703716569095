import React from 'react';

const StarHollow = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="26 32 148 148"
    className="bi bi-star-hollow"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Star hollow</title>
    <g>
      <path
        fillRule="evenodd"
        d="M170.5,83.9c0-2.1-1.6-3.4-4.8-3.9l-43-6.3l-19.3-39c-1.1-2.3-2.5-3.5-4.2-3.5c-1.7,0-3.1,1.2-4.2,3.5l-19.3,39l-43,6.3c-3.2,0.5-4.8,1.8-4.8,3.9c0,1.2,0.7,2.6,2.1,4.1l31.2,30.3l-7.4,42.9c-0.1,0.8-0.2,1.4-0.2,1.7c0,1.2,0.3,2.2,0.9,3c0.6,0.8,1.5,1.2,2.7,1.2c1,0,2.2-0.3,3.4-1L99.2,146l38.5,20.2c1.2,0.7,2.3,1,3.4,1c2.3,0,3.5-1.4,3.5-4.3c0-0.7,0-1.3-0.1-1.7l-7.4-42.9L168.3,88C169.8,86.5,170.5,85.2,170.5,83.9z M125.4,114.5l6.2,36.1l-32.3-17.1l-32.4,17.1l6.3-36.1L46.8,89.1L83,83.7L99.2,51l16.2,32.7l36.2,5.3L125.4,114.5z"
      />
    </g>
  </svg>
);

export default StarHollow;
