import React from 'react';

const SVG = ({width = '16px', height = '16px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 198.4 198.4"
    className="bi bi-minus"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Minus</title>
    <g>
      <path
        fillRule="evenodd"
        d="M164.7,93.8c0-3.3-2.2-5.5-5.5-5.5H39.2c-3.3,0-5.5,2.2-5.5,5.5v10.9c0,3.3,2.2,5.5,5.5,5.5h120.1c3.3,0,5.5-2.2,5.5-5.5V93.8z"
      />
    </g>
  </svg>
);

export default SVG;
