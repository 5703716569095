import {useState, useEffect} from 'react';
import {on, off} from '../../utils/events';
import constantsFactory from '../../utils/constants';
import basketServiceFactory, {basketMock} from '../../services/basket-service';
const {EVENTS} = constantsFactory();

const useBasket = () => {
  const [basket, setBasket] = useState(basketMock());

  useEffect(() => {
    setBasket(basketServiceFactory(document, window));

    const fetchAndUpdate = () => {
      basketServiceFactory(document, window);
    };

    on(EVENTS.BASKET_UPDATED, setBasket);
    on(EVENTS.LOGIN_SUCCESS, fetchAndUpdate);
    on(EVENTS.LOGOUT_SUCCESS, fetchAndUpdate);
    return () => {
      off(EVENTS.BASKET_UPDATED, setBasket);
      off(EVENTS.LOGIN_SUCCESS, fetchAndUpdate);
      off(EVENTS.LOGOUT_SUCCESS, fetchAndUpdate);
    };
  }, []);

  return basket;
};

export default useBasket;
