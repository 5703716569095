import {useState, useEffect} from 'react';
import _ from '../../utils/_';
import elementFactory from '../../utils/element';
import constantsFactory from '../../utils/constants';
import modalFactory from '../../../vendors/bootstrap/components/modal';
import {transitionZero} from '../../../vendors/bootstrap/components/modal';
import {modalMock} from '../../../vendors/bootstrap/components/modal';
const {KEYS} = constantsFactory();

const useModal = (target, options: optionsType = {}) => {
  const [instance, setInstance] = useState(modalMock());

  useEffect(() => {
    if (_.isNil(target.current)) {
      return;
    }

    const $ = elementFactory(document, window);
    const openTriggers = [];
    const closeTriggers = $.findAll('[data-dismiss="modal"]', target.current);
    const modal = modalFactory($, _, document, window, KEYS);
    const instance = modal(
        openTriggers,
        closeTriggers,
        target.current,
        _.merge(transitionZero(), options),
    );

    setInstance(instance);

    return () => {
      instance.destroy();
    };
  }, [target.current]);

  return instance;
};

interface optionsType {
  onClose?: (window) => any;
}

export default useModal;
